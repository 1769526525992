import React from 'react'
import styles from './MapImg.module.css'
import imgMap from '../../images/mapimg.jpg'
const MapImg = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Карта</h2>
      <img className={styles.mapimg} src={imgMap} />
    </div>
  )
}

export default MapImg
