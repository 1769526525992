import React from 'react'
import MainLayout from '../layouts/MainLayout'
import MapImg from '../components/MapImg/MapImg'

const mapimg = ({ location, onMain }) => {
  return (
    <MainLayout onMain={onMain} location={location}>
      <MapImg />
    </MainLayout>
  )
}

export default mapimg
